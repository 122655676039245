import View from "./ProductDetailsPage.pageView";

import { useElementContext } from "@ultracommerce/react-storefront/global";
import { ProductContextProvider } from "@ultracommerce/react-storefront/global/src/contexts/ProductContext";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageService";

const ProductDetailPage = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();

  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <>
          <PageContentRow>
            <ProductContextProvider>
              <ProductDetailPageView />
            </ProductContextProvider>
          </PageContentRow>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

const ProductDetailPageView = () => {
  const { componentData } = useComponentModel();
  // Add config override for product detail here
  return <View {...componentData} />;
};

export default ProductDetailPage;